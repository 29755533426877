import React from 'react';

const thStyle = {
  table: {
    fontFamily: "Anton",
    fontWeight: "normal",
    fontStyle: "normal",
    width:"95%",
    marginLeft: "2.5%"
  },
  td: {
    padding: "3px",
    fontWeight: "normal",
    textAlign: "left",
    border: "solid lightgray 0.5px"
  },
  tdi: {
    padding: "3px",
    fontWeight: "normal",
    textAlign: "right",
    border: "solid lightgray 0.5px"
  },
  th:{
    padding: "8px",
    textAlign: "left",
    fontWeight: "normal",
   },
   thf:{
    padding: "8px",
    fontSize: "20px",
    textAlign: "right",
    fontWeight: "normal",
    paddingTop: "25px"
   },
   tt:{
    padding: "8px",
    textAlign: "left",
    border: "solid gray 1px"
   }

};

class DataCedolino extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      condoItem: props.condoItem
    };
  }
  componentDidUpdate(propsPrec) {
    if (this.props.condoItem !== propsPrec.condoItem) {
      this.setState({
        condoItem: this.props.condoItem
      });
    }
  }
  render() {
    return (
      <table style={thStyle.table} className="table">
        <tbody>
          <tr>
            <th style={thStyle.th} colSpan="3">
              <b>{this.state.condoItem.denominazione}</b>
              <br/>
              Tipologia di utilizzo: <b>{this.state.condoItem.utilizzo}</b>
              <br/>
              Numero componenti familiari: <b>{this.state.condoItem.ncf}</b>
            </th>
          </tr>
          <tr>
            <th style={thStyle.tt} colSpan="3">
              <b>ACQUEDOTTO</b>
            </th>
          </tr>      
          <tr style={thStyle.tr}>
            <th style={thStyle.td} colSpan="2">Quota Fissa</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_fisso_acq}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2">Quota Variabile</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_var_acq}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2"><b>TOTALE</b></th>
            <th style={thStyle.tdi}><b>{parseFloat((+this.state.condoItem.imp_fisso_acq + +this.state.condoItem.imp_var_acq)).toFixed(2)}</b></th>
          </tr>
          <tr>
            <th style={thStyle.tt} colSpan="3">
              <b>FOGNATURA</b>
            </th>
          </tr>      
          <tr style={thStyle.tr}>
            <th style={thStyle.td} colSpan="2">Quota Fissa</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_fisso_fog}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2">Quota Variabile</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_var_fog}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2"><b>TOTALE</b></th>
            <th style={thStyle.tdi}><b>{parseFloat((+this.state.condoItem.imp_fisso_fog + +this.state.condoItem.imp_var_fog)).toFixed(2)}</b></th>
          </tr>
          <tr>
            <th style={thStyle.tt} colSpan="3">
              <b>DEPURAZIONE</b>
            </th>
          </tr>      
          <tr style={thStyle.tr}>
            <th style={thStyle.td} colSpan="2">Quota Fissa</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_fisso_dep}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2">Quota Variabile</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_var_dep}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2"><b>TOTALE</b></th>
            <th style={thStyle.tdi}><b>{parseFloat((+this.state.condoItem.imp_fisso_dep + +this.state.condoItem.imp_var_dep)).toFixed(2)}</b></th>
          </tr>
          <tr>
            <th style={thStyle.tt} colSpan="3">
              <b>ONERI DI PEREQUAZIONE</b>
            </th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2"><b>Compenti UI1, UI2, UI3, UI4</b></th>
            <th style={thStyle.tdi}><b>{this.state.condoItem.imp_oper}</b></th>
          </tr>
          <tr>
            <th style={thStyle.tt} colSpan="3">
              <b>RIEPILOGO IVA</b>
            </th>
          </tr>
          <tr style={thStyle.tr}>
            <th style={thStyle.td} colSpan="2">Imponibile</th>
            <th style={thStyle.tdi}>{parseFloat(this.state.condoItem.imp_tot_es).toFixed(2)}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2">IVA 10%</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_iva}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2"><b>TOTALE</b></th>
            <th style={thStyle.tdi}><b>{this.state.condoItem.imp_tot}</b></th>
          </tr>
          <tr>
            <th style={thStyle.tt} colSpan="3">
              <b>ALTRO</b>
            </th>
          </tr>
          <tr style={thStyle.tr}>
            <th style={thStyle.td} colSpan="2">Quota Compensazione/Acconto</th>
            <th style={thStyle.tdi}>{this.state.condoItem.imp_comp}</th>
          </tr>
          <tr>
            <th style={thStyle.td} colSpan="2"><b>TOTALE</b></th>
            <th style={thStyle.tdi}><b>{this.state.condoItem.importo}</b></th>
          </tr>
          <tr>
            <th style={thStyle.thf} colSpan="3">
              <b>TOTALE A PAGARE: {this.state.condoItem.importo} €</b>
            </th>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default DataCedolino;