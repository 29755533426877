import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AuthService from "../services/auth.service";
import OnLoad from './onload';

function checkToken(token_verify, setCheck) {
  AuthService.checkMailToken(token_verify).then(
    (user) => {
      if (user.data !== undefined) {
        setCheck("VALID");
      } else {
        setCheck("NOT_VALID");
      }
    });
}

export default function MailVerify() {

  const token_verify = useParams();
  const [check, setCheck] = React.useState(false);
  useEffect(() => {
    checkToken(token_verify, setCheck);
  }, [token_verify])

  return (<>
    {(check === "VALID") ?
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ textAlign: 'center' }} variant="h4" component="div">
            MAIL VERIFICATA CON SUCCESSO
          </Typography>
          <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
            Al termine del controllo sulla coerenza dei dati da Lei indicati nel portale <b>B4</b>, procederemo ad inviare mail di conferma di avvenuta attivazione del Suo account. Nel caso dovessimo riscontrare disallineamenti, procederemo a contattarla prontamente.
          </Typography>
          <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
            Per qualsiasi comunicazione e/o segnalazione può contattarci all’indirizzo mail <b>609@chogolisa.it</b>
          </Typography>
          <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
            Cordialmente.
          </Typography>
          <Grid container justify="center">
          </Grid>
        </CardContent>
      </Card>
      :
      (check === "NOT_VALID") ?
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography sx={{ textAlign: 'center' }} variant="h4" component="div">
              PROBLEMA IN ACCESSO
            </Typography>
            <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
              Gentile Utente abbiamo riscontrato un problema nella procedura di validazione della mail da Lei indicata. Probabilmente è trascorso troppo tempo dalla fase di inserimento dati e dalla successiva verifica account. Il portale B4 ha automaticamente aperto un ticket al reparto IT. Sarà nostra cura quella di contattarla al più presto e abilitarla all’utilizzo degli strumenti informatici di <b>B4</b>.
            </Typography>
            <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
              Per qualsiasi comunicazione e/o segnalazione può contattarci all’indirizzo mail <b>609@chogolisa.it</b>
            </Typography>
            <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
              Cordialmente.
            </Typography>
            <Grid container justify="center">
            </Grid>
          </CardContent>
        </Card>
        :
        <OnLoad />
    }
  </>
  );
}
