import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function OnLoad() {
  return (
    <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', backgroundColor:'rgba(0, 0, 0, 0.1)' }}
        >

        <Grid item xs={3}>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress size='15vh' style={{ color:'rgba(21, 101, 192, 1)' }}/>
            </Box>
        </Grid>   
        
    </Grid> 
  );
}
