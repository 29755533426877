import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReactToPrint from 'react-to-print';
import Grid from '@mui/material/Grid';
import DataCedolino from "../pdf/DataCedolino";

export class CedolinoDialogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            condoItem: props.condoItem
        };
    }
    componentDidUpdate(propsPrec) {
        if (this.props.open !== propsPrec.open) {
            this.setState({
                open: this.props.open,
                condoItem: this.props.condoItem
            });
        }
    }
    render() {
        return (
            <Dialog
                onClose={this.props.onClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.open}
                fullWidth={true}
                maxWidth={'lg'}
                >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <IconButton
                    aria-label="close"
                    onClick={this.props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                Info Calcolo Tariffa 
                </DialogTitle>
                <DialogContent dividers>
                {(this.state.condoItem)? <>
                    <DataCedolino ref={(response) => (this.componentRef = response)} condoItem={this.state.condoItem}/>
                    <ReactToPrint
                        content={() => this.componentRef}
                        trigger={() => 
                            <Grid container spacing={0} key={0} sx={{mt:2, pr:3}}>
                                <Grid item xs={8} key={1}>
                                    <></>
                                </Grid>
                                <Grid item xs={4} key={2}>
                                    <Button
                                            type="submit"
                                            size="large"
                                            variant="contained"
                                            style = {{width: '100%',height: "100%"}}
                                        >
                                        Invia
                                    </Button>
                                </Grid>
                            </Grid>
                                }
                        />
                </>
                : ""}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.props.onClose}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
            );
    }
}
export default CedolinoDialogs
