import React, { Component } from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import condoService from "../../services/condo.service";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from "@mui/material";

export default class SubEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCond: props.item,
      fiscal_code: "",
      denominazione: "",
      utilizzo: "",
      flag_fog: true,
      flag_dep: true,
      data_inizio: "",
      data_fine: "",
      consumo: "",
      tipologia: "",
      millesimali: "",
      ncf: "",
      message: null,
      error: false,
      utilizzoSet: props.utilizzoSet,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTy = this.handleChangeTy.bind(this);
    this.handleChangeDI = this.handleChangeDI.bind(this);
    this.handleChangeDF = this.handleChangeDF.bind(this);
    this.handleChangeFog = this.handleChangeFog.bind(this);
    this.handleChangeDep = this.handleChangeDep.bind(this);

  }

  handleChangeTy = (event) => {
    this.setState({
      tipologia: event.target.value
    });
  };

  handleChange = (event) => {
    this.setState({
      utilizzo: event.target.value
    });
  };
  handleChangeDI = (event) => {
    this.setState({
      data_inizio: event.target.value
    });
  };
  handleChangeDF = (event) => {
    this.setState({
      data_fine: event.target.value
    });
  };
  handleChangeFog = (event) => {
    this.setState({
      flag_fog: !this.state.flag_fog
    });
  };
  handleChangeDep = (event) => {
    this.setState({
      flag_dep: !this.state.flag_dep
    });
  };

  componentDidUpdate(propsPrec) {
    if (this.props.item !== propsPrec.item) {
      this.setState({
        currentCond: this.props.item
      });
    }
  }

  handleSubmit(event) {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let condo = this.state.currentCond;
    if (condo == null) {
      return false
    } else {
      condo = JSON.parse(condo);
      condoService.insertSubCalc(condo, data.get('fiscal_code'), data.get('denominazione'), this.state.utilizzo, this.state.millesimali, data.get('ncf'), this.state.data_inizio, this.state.data_fine, data.get('consumo'),this.state.tipologia, this.state.flag_fog, this.state.flag_dep).then(
        (response) => {
          if (response.error) {
            const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
            this.setState({
              error: true,
              message: resMessage
            });
          } else {
            document.getElementById("create-sub-course-form").reset();
            this.setState({
              currentCond: false,
              fiscal_code: "",
              denominazione: "",
              telefono: "",
              utilizzo: "",
              flag_fog: true,
              flag_dep: true,
              ncf: "",
              data_inizio: "",
              data_fine: "",
              consumo: "",
              message: null,
              error: false
            });
            this.props.handler()
          }
        }
      );
    }

  };


  componentDidMount() {

  }

  render() {
    return (
      <Box id="create-sub-course-form" component="form" onSubmit={this.handleSubmit} noValidate
        sx={{
          boxShadow: 1,
          border: 0,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          p: 1
        }}
        style={{ backgroundColor: "white" }}
      >
        {(this.state.error) ?
          <Alert severity="error">{this.state.message}</Alert>
          : ""
        }
        <Grid container spacing={1} sx={{mt:0, mb:1}}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              id="denominazione"
              defaultValue={this.state.denominazione}
              label="Denominazione"
              name="denominazione"
              error={this.state.error}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
          <InputLabel id="utilizzo_label">Utilizzo</InputLabel>
            <Select
              labelId="utilizzo_label"
              id="utilizzo"
              value={this.state.utilizzo}
              label="Utilizzo"
              style={{ width: '100%' }}
              onChange={this.handleChange}
            >
              {
                this.state.utilizzoSet.map((element, key) => (
                  <MenuItem key={key} value={element.chiave}>{element.descrizione}</MenuItem>
                ))
              }
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <TextField
              id="millesimali"
              defaultValue={this.state.millesimali}
              label="Millesimali"
              name="millesimali"
              style={{ width: '100%' }}
              error={this.state.error}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <TextField
              required
              id="ncf"
              defaultValue={this.state.ncf}
              label="NCF"
              name="ncf"
              style={{ width: '100%' }}
              error={this.state.error}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ml:2}}>
            <Grid component="label" container alignItems="center" spacing={1} xs={12}>
              <Grid item xs={6}>Fognature</Grid>
              <Grid item xs={6}>Depurazione</Grid>
            </Grid>
            <Grid component="label" container alignItems="center" spacing={1} xs={12}>
              <Grid item xs={6}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item xs="auto">NO</Grid>
                  <Grid item xs="auto">
                    <Switch
                      checked={this.state.flag_fog}
                      onChange={this.handleChangeFog}
                      name="checkedB"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs="auto">SI</Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item xs="auto">NO</Grid>
                  <Grid item xs="auto">
                    <Switch
                      checked={this.state.flag_dep}
                      onChange={this.handleChangeDep}
                      name="checkedC"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs="auto">SI</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component="div" variant="h6" sx={{mt:2}}>
              Inserisci dati prima lettura
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth>
            <InputLabel id="tipologia_label">Tipologia</InputLabel>
              <Select
                labelId="tipologia_label"
                id="tipologia"
                value={this.state.tipologia}
                label="tipologia"
                style={{ width: '100%' }}
                onChange={this.handleChangeTy}
              >
                <MenuItem key="EFFETTIVA" value="EFFETTIVA">EFFETTIVA</MenuItem>
                <MenuItem key="STIMATA" value="STIMATA">STIMATA</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              id="data_inizio"
              label="Inizio"
              type="date"
              onChange={this.handleChangeDI}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              id="data_fine"
              label="Fine"
              type="date"
              onChange={this.handleChangeDF}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <TextField
              id="consumo"
              defaultValue={this.state.consumo}
              label="Consumo"
              name="consumo"
              style={{ width: '100%' }}
              error={this.state.error}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              style = {{width: '100%',height: "100%"}}
            >
              Salva
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
