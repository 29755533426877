import axios from 'axios';
import { API_URL, authHeader } from "./auth-header";

class CondoService {

    get() {
        return axios.get(API_URL + 'condominio/get', { headers: authHeader() }).then(response => {
            if (!response.data.error) {
              return response.data.data;
            }
    
            return false;
          });
    }

    insert(cod_servizio,fiscal_code,denominazione,comune,bacini_id,provincia,indirizzo,civico,pec) {
        return axios
          .post(API_URL + "condominio/insert", {
            cod_servizio,
            fiscal_code, 
            denominazione,
            comune,
            bacini_id,
            provincia,
            indirizzo,
            civico,
            pec
          },{ headers: authHeader() })
          .then(response => {
            if (!response.data.error) {
              return response.data.data;
            }
    
            return response.data;
          });
    }

    insertSub(condo_id, fiscal_code,denominazione,utilizzo,ncf,telefono) {
        condo_id = parseInt(condo_id);
        return axios
          .post(API_URL + "condominio/insert_sub", {
            condo_id,
            fiscal_code, 
            denominazione,
            utilizzo,
            ncf,
            telefono
          },{ headers: authHeader() })
          .then(response => {
            if (!response.data.error) {
              return response.data.data;
            }
    
            return response.data;
          });
    }

    insertSubCalc(condo_id,fiscal_code, denominazione, utilizzo, millesimali, ncf, data_inizio, data_fine, consumo, tipologia, flag_fog, flag_dep) {
      condo_id = parseInt(condo_id);
      return axios
        .post(API_URL + "condominio/insert_sub_calc", {
          condo_id,
          fiscal_code, 
          denominazione,
          utilizzo,
          millesimali,
          ncf,
          data_inizio,
          data_fine,
          consumo,
          tipologia,
          flag_fog,
          flag_dep,
        },{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
  
          return response.data;
        });
    }

    getSub(condo_id) {
        return axios.post(API_URL + 'condominio/get_sub', {
            condo_id,
          }, { headers: authHeader() }).then(response => {
            if (!response.data.error) {
              return response.data.data;
            }
    
            return false;
          });
    }

    calcolaTariffe(condo_id, importo, acconto, consumi_reali, consumo_totale, data_inizio,data_fine){
      condo_id = parseInt(condo_id);
        return axios
          .post(API_URL + "condominio/calcola_tariffa", {
            condo_id,
            importo,
            acconto,
            consumi_reali,
            consumo_totale,
            data_inizio,
            data_fine,
          },{ headers: authHeader() })
          .then(response => {
            if (!response.data.error) {
              return response.data.data;
            }
    
            return response.data;
          });
    }

    editSub(data) {
      return axios
        .post(API_URL + "condominio/edit_sub", data,{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
          return response.data;
        });
    }

    calcSingSub(data) {
      return axios
        .post(API_URL + "condominio/calc_sing_sub", data,{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
          return response.data;
        });
    }

    deleteSub(condosub_id) {
      condosub_id = parseInt(condosub_id);
      return axios
        .post(API_URL + "condominio/delete_sub", {
          condosub_id,
        },{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
          return response.data;
        });
    }
    
    deleteCond(condo_id) {
      condo_id = parseInt(condo_id);
      return axios
        .post(API_URL + "condominio/delete", {
          condo_id,
        },{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
          return response.data;
        });
    }

    deleteLettura(lettura_id) {
      lettura_id = parseInt(lettura_id);
      return axios
        .post(API_URL + "condominio/delete_sub_lettura", {
          lettura_id,
        },{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
          return response.data;
        });
    }
    
    getSubLetture(condosub_id) {
      return axios.post(API_URL + 'condominio/get_sub_letture', {
        condosub_id,
        }, { headers: authHeader() }).then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
  
          return false;
        });
    }

    insertSubLettura(condosub_id, data_inizio, data_fine, consumo, tipologia) {
      condosub_id = parseInt(condosub_id);
      return axios
        .post(API_URL + "condominio/insert_sub_lettura", {
          condosub_id,
          data_inizio,
          data_fine,
          consumo,
          tipologia,
        },{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
  
          return response.data;
        });
    }
    editSubLettura(condosub_id, lettura_id, data_inizio, data_fine, consumo, tipologia) {
      condosub_id = parseInt(condosub_id);
      return axios
        .post(API_URL + "condominio/insert_sub_lettura", {
          condosub_id,
          lettura_id,
          data_inizio,
          data_fine,
          consumo,
          tipologia,
        },{ headers: authHeader() })
        .then(response => {
          if (!response.data.error) {
            return response.data.data;
          }
  
          return response.data;
        });
    }
}

export default new CondoService();