import React, { Component } from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import condoService from "../../services/condo.service";

export default class Tariffa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCond: props.currentCond,
      importo: "",
      consumo_totale: 0,
      acconto: 0,
      data_inizio: null,
      data_fine: null,
      message: null,
      consumiReali: true,
      error: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDI = this.handleChangeDI.bind(this);
    this.handleChangeDF = this.handleChangeDF.bind(this);
    this.handleChangeCT = this.handleChangeCT.bind(this);
  }

  componentDidUpdate(propsPrec) {
    if (this.props.currentCond !== propsPrec.currentCond) {
      this.setState({
        currentCond: this.props.currentCond
      });
    }
  }

  handleSubmit(event) {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let condo = this.state.currentCond;
    if (condo == null) {
      return false
    } else {
      condo = JSON.parse(condo);
        condoService.calcolaTariffe(condo, data.get('importo'), data.get('acconto'),this.state.consumiReali,this.state.consumo_totale,this.state.data_inizio,this.state.data_fine).then(
          (response) => {
            if (response.error) {
              const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
              this.setState({
                error: true,
                message: resMessage
              });
            } else {
              this.setState({
                currentCond: false,
                importo: "",
                acconto: 0,
                message: null,
                error: false
              });
              this.props.handler()
              this.props.handlerRefresh()
            }
          }
        );
    }
  };

  handleChange() {
    this.setState({
      consumiReali: !this.state.consumiReali
    })
  }
  handleChangeDI = (event) => {
    this.setState({
      data_inizio: event.target.value
    });
  };
  handleChangeDF = (event) => {
    this.setState({
      data_fine: event.target.value
    });
  };
  handleChangeCT = (event) => {
    this.setState({
      consumo_totale: event.target.value
    });
  };

  render() {
    return (
      <Box component="form" onSubmit={this.handleSubmit} noValidate
        sx={{ mt: -2 }}
      >
        <Grid container spacing={1} sx={{ mt: 0, mb: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography component="div" sx={{ mt: 1 }}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Millesimali</Grid>
                <Grid item>
                  <Switch
                    checked={this.state.consumiReali}
                    onChange={this.handleChange}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Grid>
                <Grid item>Consumi</Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              margin="normal"
              required
              type="valute"
              id="importo"
              defaultValue={this.state.importo}
              label="Importo"
              name="importo"
              error={this.state.error}
              sx={{ mr: 1 }}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              margin="normal"
              type="valute"
              id="acconto"
              defaultValue={this.state.acconto}
              label="Acconto precedente bolletta"
              name="acconto"
              error={this.state.error}
              sx={{ mr: 1 }}
              style={{ width: '100%' }}
            />
          </Grid>
          {(!this.state.consumiReali) ?
            <>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    margin="normal"
                    required
                    type="valute"
                    id="consumo_totale"
                    defaultValue={this.state.consumo_totale}
                    onChange={this.handleChangeCT}
                    label="Consumo Totale"
                    name="consumo_totale"
                    error={this.state.error}
                    sx={{ mr: 1 }}
                    style={{ width: '100%' }}
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  id="date_inizio"
                  label="Inizio"
                  type="date"
                  // name="date_inizio"
                  onChange={this.handleChangeDI}
                  // defaultValue="2020-01-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mt: 2, mr: 1 }}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  id="date_fine"
                  label="fine"
                  type="date"
                  // name="date_fine"
                  onChange={this.handleChangeDF}
                  // defaultValue="2020-02-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mt: 2, mr: 1 }}
                  style={{ width: '100%' }}
                />
              </Grid>
            </>
            :
            <></>
          }
          <Grid item xs={12} sm={12} md={2}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ mt: 2}}
              style={{ width: '100%', height: "70%" }}
            >
              Calcola
            </Button>
          </Grid>
        </Grid>
        {(this.state.error) ?
          <Alert severity="error">{this.state.message}</Alert>
          : ""
        }

      </Box>
    );
  }
}
