import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import AuthService from "../services/auth.service";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import SettingsIcon from '@mui/icons-material/Settings';
import CalculateIcon from '@mui/icons-material/Calculate';
import HelpIcon from '@mui/icons-material/Help';
import Copyright from "./copyright.component";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


export class AppSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.logout = this.logout.bind(this);
    this.clickGest = this.clickGest.bind(this);
    this.clickMedia = this.clickMedia.bind(this);
    this.clickCalc = this.clickCalc.bind(this);                
    this.clickSett = this.clickSett.bind(this);        
  }

  logout() {
    this.setState({open:false})
    AuthService.logout()
    this.props.navigate("/login"); 
  }

  clickGest(){
    this.props.navigate("/profile");         
  }
  clickMedia(){
    this.props.navigate("/media");         
  }
  clickCalc(){
    this.props.navigate("/profile-simple");         
  }
  clickSett(){
    this.props.navigate("/settings");         
  }
render(){

  const handleDrawerOpen = () => {
    this.setState({open:true});
  };

  const handleDrawerClose = () => {
    this.setState({open:false});
  };
  
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={this.state.open} style={{ background: '#f8f8f8', color: "#393244", }} sx={{
              boxShadow: "0"}}> 
        <Toolbar>
        {(this.props.loggedIn)?
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(this.state.open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          :
          ""
          }
          <Typography variant="h6" style={{flexGrow: 1}}>
            609/2021/R/IDR
          </Typography>
          {(this.props.loggedIn)?
          <Button color="inherit" onClick={this.logout} style={{ color: "#393244" }}>Logout</Button>
          :
          ""
          }
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={this.state.open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem button key={'gestione_condomini'} onClick={this.clickGest}>
            <ListItemIcon>
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText primary={'Gestione Condomini'} />
          </ListItem>
        </List>
        <List>
          <ListItem button key={'media'} onClick={this.clickMedia}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={'Media e Guide'} />
          </ListItem>
        </List>
        {/* <List>
          <ListItem button key={'calcolo_semplice'} onClick={this.clickCalc}>
            <ListItemIcon>
              <CalculateIcon />
            </ListItemIcon>
            <ListItemText primary={'Calcolo semplice'} />
          </ListItem>
        </List> */}
        <Divider />
        <List>
          <ListItem button key={'settings'} onClick={this.clickSett}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Impostazioni tariffe'} />
          </ListItem>
        </List>
      </Drawer>
      <Main open={this.state.open} style={{ backgroundColor: "#f1f1f1", minHeight: "100vh" }}>
        <DrawerHeader />
        {this.props.element}
        <Copyright />
      </Main>
    </Box>
  );
}
  
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <AppSideBar {...props} navigate={navigate} />
}

export default WithNavigate
