import axios from 'axios';
import { API_URL, authHeader } from "./auth-header";

class TariffaService {

    get() {
        return axios.get(API_URL + 'setting/get_tariffe', { headers: authHeader() }).then(response => {
            if (!response.data.error) {
              return response.data.data;
            }
    
            return false;
          });
    }

    getUse() {
      return axios.get(API_URL + 'setting/get_tariffe_utilizzo', { headers: authHeader() }).then(response => {
        if (!response.data.error) {
          return response.data.data;
        }

        return false;
      });
    }

    getComuniList() {
      return axios.get(API_URL + 'setting/get_tariffe_comuni', { headers: authHeader() }).then(response => {
        if (!response.data.error) {
          return response.data.data;
        }

        return false;
      });
    }
}

export default new TariffaService();