import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { FILE_URL } from '../../services/auth-header';

function useForceUpdate(){
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

export default function Review(props) {
  const [user] = React.useState(props.user);
  const [message, setMessage] = React.useState(props.message);

  const forceUpdate = useForceUpdate();

  function handleChange(event) {
    let tmpUser = user;
    let tmpMessage = message;
    tmpUser[event.target.name] = (event.target.checked) ? 1 : 0;
    props.updateUser(tmpUser);
    if(event.target.checked){
      delete tmpMessage[event.target.name];
      props.updateMessage(tmpMessage);
    }else{
      tmpMessage[event.target.name] = "Campo obbligatorio";
      props.updateMessage(tmpMessage);
    }
    return setMessage(tmpMessage);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Riepilogo
      </Typography>
      <Grid container spacing={2} onChange={forceUpdate}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Dati personali
          </Typography>
          <Typography gutterBottom><b>{user.email}</b></Typography>
          <Typography gutterBottom><b>{user.cf}</b></Typography>
          <Typography gutterBottom>{user.nome} {user.cognome}</Typography>
          <Typography gutterBottom>{user.indirizzo}</Typography>
          <Typography gutterBottom>{user.cap} {user.comune} ({user.pr})</Typography>
        </Grid>
        <Grid item container direction="column" xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Dati societá
          </Typography>
          <Grid container>
              <React.Fragment key="denominazione">
                <Grid item xs={6}>
                  <Typography gutterBottom>Denominazione</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{user.denominazione}</Typography>
                </Grid>
              </React.Fragment>
              <React.Fragment key="fiscal_code">
                <Grid item xs={6}>
                  <Typography gutterBottom>C.F./P.iva</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{user.fiscal_code}</Typography>
                </Grid>
              </React.Fragment>
              <React.Fragment key="rea_cod">
                <Grid item xs={6}>
                  <Typography gutterBottom>Codice REA</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{user.rea_cod}</Typography>
                </Grid>
              </React.Fragment>
              <React.Fragment key="cf_rapp_legale">
                <Grid item xs={6}>
                  <Typography gutterBottom>C.F. Rappresentante Legale</Typography>
                </Grid>
                <Grid item xs={12}>
                <FormControl
                  required
                  error={message.termini_condizioni}
                  component="fieldset"
                  sx={{ mt: 2}}
                  variant="standard"
                >
                  <FormLabel component="legend">Accettazione</FormLabel>
                  <FormGroup>
                  <FormControlLabel
                        control={<Checkbox color="secondary" name="termini_condizioni" checked={(user.termini_condizioni) ? 1 : 0} error={message.termini_condizioni} onChange={handleChange}/>}
                        label={
                        <>Accetto{" "}
                          <Link target="_blank" href={FILE_URL + "storable/Acda_Gdpr_b4_amministratore%20Titolare.pdf"} >
                            {"Termini e Condizioni d'uso"}
                          </Link>
                        </>
                        }
                    />
                  </FormGroup>
                  <FormHelperText>{message.termini_condizioni}</FormHelperText>
                </FormControl>
                </Grid>
              </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}