import { Navigate } from "react-router-dom";
import { useState } from "react";
import OnLoad from "./onload";
import AuthService from "../services/auth.service";

const Public = ({ allUser, children }) => {
    const [onLoad, setOnLoad] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    if(onLoad){
        AuthService.getCurrentUser().then(
            (user) => {
              if(user.data !== undefined){
                setLoggedIn(true);
                setOnLoad(false);
                return ;
              }else if(user === false){
                setOnLoad(false);
                return children;
              }
            });
        return (
          <OnLoad />
        )
      }else{
        if (loggedIn) {
            return <Navigate to="/profile" replace />;
        }else{
            return children;
        }
      }
};
export default Public;