import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DatiUtente from '../components/registration/DatiUtente'
import DatiSocieta from '../components/registration/DatiSocieta';
import Review from '../components/registration/Review';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';

import userService from "../services/user.service";

const steps = ['Dati personali', 'Dati societa', 'Riepilogo dati'];

function getPlatformList(setPlatformList) {
  userService.getPlatformList().then(
    (responseComuni) => {
      setPlatformList(responseComuni);
    }
  )
}

function getStepContent(step, user, updateUser, message, updateMessage, userfile, updateUserfile, platformList) {
  switch (step) {
    case 0:
      return <DatiUtente updateUser={updateUser} user={user} message={message} updateMessage={updateMessage} userfile={userfile} updateUserfile={updateUserfile}/>;
    case 1:
      return <DatiSocieta updateUser={updateUser} user={user} message={message} updateMessage={updateMessage} platformList={platformList}/>;
    case 2:
      return <Review updateUser={updateUser} user={user} message={message} updateMessage={updateMessage}/>;
    default:
      throw new Error('Unknown step');
  }
}

function checkStepForm(step, user, setMessage, setButtonDisabled) {
  var tmpMessage = {}
  switch (step) {
    case 0:
      tmpMessage.nome = (user.nome === "")? "Campo obbligatorio" : undefined;
      tmpMessage.cognome = (user.cognome === "")? "Campo obbligatorio" : undefined;
      tmpMessage.email = (user.email === "")? "Campo obbligatorio" : undefined;
      tmpMessage.cf = (user.cf === "")? "Campo obbligatorio" : undefined;
      tmpMessage.indirizzo = (user.indirizzo === "")? "Campo obbligatorio" : undefined;
      tmpMessage.comune = (user.comune === "")? "Campo obbligatorio" : undefined;
      tmpMessage.pr = (user.pr === "")? "Campo obbligatorio" : undefined;
      tmpMessage.cap = (user.cap === "")? "Campo obbligatorio" : undefined;
      if(tmpMessage.nome === undefined && tmpMessage.cognome === undefined && tmpMessage.email === undefined && tmpMessage.cf === undefined && tmpMessage.indirizzo === undefined && tmpMessage.comune === undefined && tmpMessage.pr === undefined && tmpMessage.cap === undefined)
      {
        setButtonDisabled(false);
        return true;
      }
      setButtonDisabled(true);
      setMessage(tmpMessage);
      break;
    case 1:
      tmpMessage.denominazione = (user.denominazione === "")? "Campo obbligatorio" : undefined;
      tmpMessage.fiscal_code = (user.fiscal_code === "")? "Campo obbligatorio" : undefined;
      tmpMessage.pec = (user.pec === "")? "Campo obbligatorio" : undefined;
      tmpMessage.platform = (user.platform === "")? "Campo obbligatorio" : undefined;
      tmpMessage.indirizzo_amministratore = (user.indirizzo_amministratore === "")? "Campo obbligatorio" : undefined;
      tmpMessage.comune_amministratore = (user.comune_amministratore === "")? "Campo obbligatorio" : undefined;
      tmpMessage.pr_amministratore = (user.pr_amministratore === "")? "Campo obbligatorio" : undefined;
      tmpMessage.cap_amministratore = (user.cap_amministratore === "")? "Campo obbligatorio" : undefined;
      tmpMessage.phone_no = (user.phone_no === "")? "Campo obbligatorio" : undefined;
      if(tmpMessage.denominazione === undefined && tmpMessage.pec === undefined && tmpMessage.platform === undefined && tmpMessage.fiscal_code === undefined && tmpMessage.indirizzo_amministratore === undefined && tmpMessage.comune_amministratore === undefined && tmpMessage.pr_amministratore === undefined && tmpMessage.cap_amministratore === undefined && tmpMessage.pohone_no === undefined)
      {
        setButtonDisabled(false);
        return true;
      }
      setButtonDisabled(true);
      setMessage(tmpMessage);
      break;
    default:
      break;
  }
  return false;
}

const theme = createTheme();

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [message, setMessage] = React.useState({});
  const [messageTxt, setMessageTxt] = React.useState(false);
  const [userfile, setUserfile] = React.useState(0);
  const [platformList, setPlatformList] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const [user, setUser] = React.useState({
    nome: "",
    cognome: "",
    email: "",
    cf: "",
    indirizzo: "",
    comune: "",
    pr: "",
    cap: "",
    denominazione: "",
    fiscal_code: "",
    pec: "",
    email_amministratore: "",
    platform: "",
    indirizzo_amministratore: "",
    comune_amministratore: "",
    pr_amministratore: "",
    cap_amministratore: "",
    phone_no: "",
    termini_condizioni: "",
  })

  const updateUser = (user) => {
    setUser(user);
    checkStepForm(activeStep, user, setMessage, setButtonDisabled);
  }

  const updateMessage = (message) => {
    setMessage(message);
  }

  const updateUserfile = (userfile) => {
    setUserfile(userfile);
  }
  
  const handleNext = () => {
    if((activeStep + 1) === steps.length){
      userService.registration(user,userfile).then(
        (response) => {
          if (response.error) {
            setMessage(response.message);
            const messageObj = Object.values(response.message);
            setMessageTxt(messageObj);
            setActiveStep(0);
          } else {
            window.location.replace("/profile");
          }
        }
      );
    }else{
      if(checkStepForm(activeStep, user, setMessage, setButtonDisabled)){
        setActiveStep(activeStep + 1);
      }else{
        setActiveStep(activeStep);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    getPlatformList(setPlatformList);
  }, [])
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md" >
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Registrati
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <>
          {
            (messageTxt!==false) ?
              messageTxt.map((element, key) => (
                <Alert key={key} severity="error" sx={{ mb:1}}>{element}</Alert>
              ))
            : ""
          }
          </>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                </Typography>
                <Typography variant="subtitle1">
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, user, updateUser, message, updateMessage, userfile, updateUserfile, platformList)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Indietro
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    disabled={(buttonDisabled)? true : false}
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? 'Conferma' : 'Avanti'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                {"Sei giá registrato? Accedi!"}
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}