import React, { Component } from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

import condoService from "../../services/condo.service";

export default class Condoedit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currendCond: false,
            cod_servizio: "",
            fiscal_code: "", 
            denominazione: "",
            selectedComune: false,
            bacini_id: "",
            provincia: "",
            indirizzo: "",
            civico: "",
            message: null,
            error: false,
            comuniList: props.comuniList,
          };
          this.handleSubmit = this.handleSubmit.bind(this);
          this.handlerAutoComuni = this.handlerAutoComuni.bind(this);       
    }

    handlerAutoComuni(value){
      this.setState({
        selectedComune: value
      });
    }

    handleSubmit(event) {
    
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        condoService.insert(data.get('cod_servizio'), data.get('fiscal_code'), data.get('denominazione'), this.state.selectedComune.bacino, this.state.selectedComune.id, data.get('provincia'), data.get('indirizzo'), data.get('civico'), data.get('pec')).then(
          (response) => {
            if (response.error) {
              const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
              this.setState({
                error: true,
                message: resMessage
              });
            } else {
              document.getElementById("create-course-form").reset();
                this.setState({
                    currendCond: false,
                    cod_servizio: "",
                    fiscal_code: "", 
                    denominazione: "",
                    selectedComune: false,
                    bacini_id: "",
                    provincia: "",
                    indirizzo: "",
                    civico: "",
                    pec: "",
                    message: null,
                    error: false
                  });     
            }
            this.props.handler()
          }
        );
      };

      componentDidUpdate(propsPrec) {
        if (this.props.comuniList !== propsPrec.comuniList) {
            this.setState({
              comuniList: this.props.comuniList
            });
        }
      }

    render() {
        return (
            <Box id="create-course-form" component="form" onSubmit={this.handleSubmit} noValidate 
            sx={{
              boxShadow: 1,
              border: 0,
              borderColor: 'primary.light',
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              p: 1
            }}
            style={{backgroundColor:"white"}}>
                              {(this.state.error) ?
                <Alert severity="error">{this.state.message}</Alert>
                : ""
              }
              <Grid container spacing={1} sx={{mt:0, mb:1}}>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    required
                    id="fiscal_code"
                    defaultValue={this.state.fiscal_code}
                    label="C.F./P.iva"
                    name="fiscal_code"
                    error={this.state.error}
                    style = {{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    id="cod_servizio"
                    defaultValue={this.state.cod_servizio}
                    label="Cod.Servizio"
                    name="cod_servizio"
                    error={this.state.error}
                    style = {{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  required
                  id="denominazione"
                  defaultValue={this.state.denominazione}
                  label="Denominazione"
                  name="denominazione"
                  error={this.state.error}
                  style = {{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    required
                    id="comuneList"
                    name="comuneList"
                    getOptionLabel={(option) => option.bacino}
                    options={(this.state.comuniList)? this.state.comuniList : []}
                    onChange={(event, value) => this.handlerAutoComuni(value)} 
                    style = {{width: '100%'}}
                    renderInput={(params) => <TextField {...params} label="Comune" />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <TextField
                  required
                  id="provincia"
                  defaultValue={this.state.provincia}
                  label="Provincia"
                  name="provincia"
                  error={this.state.error}
                  style = {{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  required
                  id="indirizzo"
                  defaultValue={this.state.indirizzo}
                  label="Indirizzo"
                  name="indirizzo"
                  error={this.state.error}
                  style = {{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={1}>
                  <TextField
                  required
                  id="civico"
                  defaultValue={this.state.civico}
                  label="Civico"
                  name="civico"
                  error={this.state.error}
                  style = {{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                  id="pec"
                  defaultValue={this.state.pec}
                  label="PEC"
                  name="pec"
                  error={this.state.error}
                  style = {{width: '100%'}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    style = {{width: '100%',height: "100%"}}
                  >
                    Salva
                  </Button>
                </Grid>
              </Grid>
            </Box>
        );
    }
}
