import React, { Component } from "react";
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
  useGridApiRef,
  DataGrid,
} from '@mui/x-data-grid';

import Typography from '@mui/material/Typography';
import condoService from "../../../services/condo.service";
import LoopIcon from '@mui/icons-material/Loop';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Tooltip from '@mui/material/Tooltip';

function showDate(params) {
  if(params.row.data_inizio){
    return( 
      <Typography variant="body1" color="black" >
        <b>Inizio: </b>{params.row.data_inizio} <br/>
        <b>FIne: </b>{params.row.data_fine} 
      </Typography>
    )
  }
}


function UseGridApiRefPers () {
  return useGridApiRef();
}

export default class LetturaList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows
      };
      // this.handleSubmit = this.handleSubmit.bind(this);        
      const RowMenuCell = {}
      RowMenuCell.propTypes = {
        api: PropTypes.object.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      };
      this.RowMenuCell = this.RowMenuCell.bind(this);
    }
    
    RowMenuCell(props) {
      const { api, id } = props;
      const classes = {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          gap: 1,
          color: 'red',
        },
        textPrimary: 'black'
      };

      const handleEditClick = (event) => {
        event.stopPropagation();
        const row = api.getRow(id);
        this.props.handleLetturaRow(row);
        // condoService.editSub(row).then(
        //   (response) => {
        //     if (response.error) {
        //     } else {
        //       api.updateRows([{ ...row, isNew: false }]);
        //       this.props.handler()
        //     }
        //   }
        // );
      };
    
      const handleSaveClick = (event) => {
        event.stopPropagation();
        api.commitRowChange(id);
        api.setRowMode(id, 'view');

        
      };
    
      const handleDeleteClick = (event) => {
        event.stopPropagation();
        condoService.deleteLettura(id).then(
          (response) => {
            if (response.error) {
            } else {
              api.updateRows([{ id, _action: 'delete' }]);
              this.props.handler()
            }
          }
        );
      };
    
      return (
        <div className={classes.root}>
          <Tooltip title="Modifica">
            <IconButton
              color="inherit"
              size="small"
              aria-label="edit"
              onClick={handleEditClick}
            >
              <EditIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancella">
            <IconButton
              color="inherit"
              size="small"
              aria-label="delete"
              onClick={handleDeleteClick}
            >
              <DeleteIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    componentDidUpdate(propsPrec) {
      if (this.props.rows !== propsPrec.rows) {
          this.setState({
            rows: this.props.rows
          });
      }
    }

    render() {
      const apiRef = UseGridApiRefPers
      const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const columns = [
      { field: 'tipologia', headerName: 'Tipologia', minWidth: 150, flex:0.3, editable: false},
      { field: 'data_inizio', headerName: 'Date', minWidth: 150, flex:0.2, renderCell:showDate},
      { field: 'gg', headerName: 'GG', minWidth: 150, flex:0.1 },
      { field: 'consumo', headerName: 'Co', minWidth: 150, flex:0.1 },
      {
        field: 'actions',
        headerName: 'Azioni',
        renderCell: this.RowMenuCell,
        sortable: false,
        minWidth: 150,
        flex:0.3, 
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
      },
    ];

  return (
    <>
    <div style={{ height: 350, width: '100%' , backgroundColor: "white" }}>
      <DataGrid
        rows={this.state.rows}
        rowHeight={105}
        columns={columns}
        apiRef={apiRef}
        editMode="row"
        sx={{
          boxShadow: 1,
          border: 0,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        componentsProps={{
          toolbar: { apiRef },
        }}
      />
    </div>
    </>

  );
}
}