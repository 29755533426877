import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

const useStyles = {
  root: {
    width: '100%',
  },
  heading: {
    fontSize: 15,
  },
  secondaryHeading: {
    fontSize: 15,
    color: "secondary",
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid`,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export class Settingaccordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipo: props.item.tipo,
            codice: props.item.codice,
            cm: props.item.cm,
            scaglione: props.item.scaglione,
            unita_misura: props.item.unita_misura,
            codice_dettaglio: props.item.codice_dettaglio,
            descrizione: props.item.descrizione,
            importo: props.item.importo,
            consumo: props.item.consumo,
            tipo_uso: props.item.tipo_uso,
            error: false,
            message: ""
        };
    }

  render() {
    const classes = useStyles;

    return (
        <div className={classes.root}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <div className={classes.column}>
                <Typography style={classes.heading}><b>{this.state.codice}</b></Typography>
                <Typography style={classes.secondaryHeading}>{this.state.descrizione}</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails style={classes.details}>

              <div className={clsx(classes.column, classes.helper)}>
              <TextField
                    margin="normal"
                    required
                    id="tipo_uso"
                    defaultValue={this.state.tipo_uso}
                    label="TIPO USO"
                    name="tipo_uso"
                    error={this.state.error}
                    sx={{mr: 1}}
                    style = {{width: '25ch'}}
                />
                <TextField
                    margin="normal"
                    required
                    id="codice_dettaglio"
                    defaultValue={this.state.codice_dettaglio}
                    label="COD DETTAGLIO"
                    name="codice_dettaglio"
                    error={this.state.error}
                    sx={{mr: 1}}
                    style = {{width: '25ch'}}
                />
                <TextField
                    margin="normal"
                    required
                    id="cm"
                    defaultValue={this.state.cm}
                    label="C.M."
                    name="cm"
                    error={this.state.error}
                    sx={{mr: 1}}
                    style = {{width: '10ch'}}
                />
                <TextField
                    margin="normal"
                    required
                    id="scaglione"
                    defaultValue={this.state.scaglione}
                    label="SCAGLIONE"
                    name="scaglione"
                    error={this.state.error}
                    sx={{mr: 1}}
                    style = {{width: '10ch'}}
                />
                <TextField
                    margin="normal"
                    required
                    id="unita_misura"
                    defaultValue={this.state.unita_misura}
                    label="UNITA' DI MISURA"
                    name="unita_misura"
                    error={this.state.error}
                    sx={{mr: 1}}
                    style = {{width: '10ch'}}
                />
                <TextField
                    margin="normal"
                    required
                    id="importo"
                    defaultValue={this.state.importo}
                    label="Importo"
                    name="importo"
                    error={this.state.error}
                    sx={{mr: 1}}
                    style = {{width: '25ch'}}
                />
                <TextField
                    margin="normal"
                    required
                    id="consumo"
                    defaultValue={this.state.consumo}
                    label="Consumo"
                    name="consumo"
                    error={this.state.error}
                    sx={{mr: 1}}
                    style = {{width: '25ch'}}
                />
              </div>
              <div>
              {(this.state.error) ?
                <Alert severity="error" sx={{marginTop: 1}}>{this.state.message}</Alert>
                : <></>
              }
              </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button size="small" color="primary">
                Salva
              </Button>
            </AccordionActions>
          </Accordion>
        </div>
      );
  }
  
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Settingaccordion {...props} navigate={navigate} />
}

export default WithNavigate
