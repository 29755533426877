import * as React from 'react';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import Typography from '@mui/material/Typography';
import { FILE_URL } from '../services/auth-header';



export default function Media() {
    const [openReg, setOpenReg] = React.useState(true);
    const [openUtil, setOpenUtil] = React.useState(true);

    const handleClickReg = () => {
        setOpenReg(!openReg);
    };
    const handleClickUtil = () => {
        setOpenUtil(!openUtil);
    };

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ textAlign: 'left' }} variant="h4" component="div">
                    Guide e Media
                </Typography>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItemButton onClick={handleClickReg}>
                        <ListItemIcon>
                            <DoubleArrowIcon />
                        </ListItemIcon>
                        <ListItemText primary="Registrazione" />
                        {openReg ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openReg} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link target="_blank" href={FILE_URL + "storable/Manuale_registrazione_B4_609_21.pdf"} >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <PictureAsPdfIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Guida PDF" />
                                </ListItemButton>
                            </Link>
                            <Link target="_blank" href="https://www.linkedin.com/posts/chogolisa-srl_arera-6092021ridr-activity-6977967805669232641-fwig?utm_source=share&utm_medium=member_desktop" >
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <SlideshowIcon />
                                </ListItemIcon>
                                <ListItemText primary="Guida Video" />
                            </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleClickUtil}>
                        <ListItemIcon>
                            <DoubleArrowIcon />
                        </ListItemIcon>
                        <ListItemText primary="Utilizzo piattaforma" />
                        {openUtil ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openUtil} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link target="_blank" href={FILE_URL + "storable/Manuale_utlizzo_ripartizione_tariffaria_B4_609_2021.pdf"} >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <PictureAsPdfIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Guida PDF" />
                                </ListItemButton>
                            </Link>
                            <Link target="_blank" href="https://www.linkedin.com/posts/chogolisa-srl_arera-6092021-b4-proposta-chogolisa-activity-6978342826531000320-CHR9/?utm_source=share&utm_medium=member_desktop" >
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <SlideshowIcon />
                                </ListItemIcon>
                                <ListItemText primary="Guida Video" />
                            </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                </List>
            </CardContent>
        </Card>
    );
}
