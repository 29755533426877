import React, { Component } from "react";
import { Routes, Route} from "react-router-dom";

import AppSideBar from "./components/appsidebar.component";
import Login from './pages/login';
import Profile from './pages/profile';
import ProfileSimple from "./pages/profile-simple";
import Calculation from './pages/calculation';
import Media from "./pages/media";
import AuthService from "./services/auth.service";
import TariffeSet from "./pages/tariffesett";
import Protected from "./pages/protected";
import ProtectedNotVer from "./pages/protectednotver";
import Public from "./pages/public";
import NotFound from "./pages/notfound";
import Registration from './pages/registration';
import OnVerify from "./pages/onverify";
import MailVerify from "./pages/mailverify";
import PassChange from "./pages/passchange";
import PassChangeReq from "./pages/passchangereq";
import ValidateUser from "./pages/validateuser";

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoad: true,
    };
    this.logOut = this.logOut.bind(this);
  }

  logOut() {
    AuthService.logout();
  }

  render() {
      return (
        <div style={{ background: '#f8f8f8'}}>
          <div className="container mt-3" >
            <Routes>            
              <Route exact path="/"
                element={
                <Protected typePermission={"USER"}>
                  <AppSideBar element={<Profile />} loggedIn={true}/>
                </Protected>
                }
              />
              <Route exact path='/onverify'
                element={
                  <ProtectedNotVer typePermission={"USER"}>
                    <AppSideBar element={<OnVerify />} loggedIn={true}/>
                  </ProtectedNotVer>
                }
              />
              <Route exact path='/profile'
                element={
                <Protected typePermission={"USER"}>
                  <AppSideBar element={<Profile />} loggedIn={true}/>
                </Protected>
                }
              />
              <Route exact path='/profile-simple'
                element={
                <Protected typePermission={"USER"}>
                  <AppSideBar element={<ProfileSimple />} loggedIn={true}/>
                </Protected>
                }
              />
              <Route exact path='/calculation'
                element={
                <Protected typePermission={"USER"}>
                  <AppSideBar element={<Calculation />} loggedIn={true}/>
                </Protected>
                }
              />
              <Route exact path='/settings'
                element={
                <Protected typePermission={"USER"}>
                  <AppSideBar element={<TariffeSet />} loggedIn={true}/>
                </Protected>
                }
              />
              <Route exact path='/media'
                element={
                <Protected typePermission={"USER"}>
                  <AppSideBar element={<Media />} loggedIn={true}/>
                </Protected>
                }
              />
              <Route path='*'
                element={
                <Protected typePermission={"USER"}>
                  <AppSideBar element={<NotFound />} loggedIn={true}/>
                </Protected>
                }
              />
              <Route exact path='/login'
                element={
                <Public typePermission={"USER"}>
                  <AppSideBar element={<Login />} loggedIn={false}/>
                </Public>
                }
              />
              <Route exact path='/registration'
                element={
                <Public typePermission={"USER"}>
                  <AppSideBar element={<Registration />} loggedIn={false}/>
                </Public>
                }
              />
              <Route exact path='/passchangereq'
                element={
                <Public typePermission={"USER"}>
                  <AppSideBar element={<PassChangeReq />} loggedIn={false}/>
                </Public>
                }
              />
              <Route exact path='/passchange/:token_verify'
                element={
                  <Public typePermission={"USER"}>
                    <AppSideBar element={<PassChange />} loggedIn={false}/>
                  </Public>
                }
              />
              <Route exact path='/mailverify/:token_verify'
                element={
                  <AppSideBar element={<MailVerify />} loggedIn={false}/>
                }
              />
  
              <Route exact path='/validateuser/:token_verify'
                element={
                  <AppSideBar element={<ValidateUser />} loggedIn={false}/>
                }
              />
            </Routes>
          </div>
        </div>
      );  
  }
}

export default App;
