import * as React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function NotFound() {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 18,textAlign: 'center' }} color="text.secondary" gutterBottom>
          Errore pagina non trovata.
        </Typography>
        <Typography sx={{textAlign: 'center' }} variant="h1" component="div">
          404
        </Typography>
        <Typography sx={{fontSize: 18, mb: 1.5, textAlign: 'center' }} color="text.secondary">
          PAGE NOT FOUND
        </Typography>
        <Typography sx={{fontSize: 15, textAlign: 'center' }} variant="body2">
          Ci dispiace non ci sono pagine per questo Indirizzo, torna nella home dal link in basso.
          <br />
          <Link underline="none" to="/" sx={{textAlign: 'center' }}>Torna alla Home</Link>
        </Typography>
        <Grid container justify="center">

      </Grid>
      </CardContent>


    </Card>
  );
}
