import { Navigate } from "react-router-dom";
import { useState } from "react";
import OnLoad from "./onload";
import AuthService from "../services/auth.service";

const ProtectedNotVer = ({ typePermission, children }) => {
    const [onLoad, setOnLoad] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [validate, setValidate] = useState(false);
    switch (typePermission) {
      default:
        if(onLoad){
          AuthService.getCurrentUser().then(
              (user) => {
                if(user.data !== undefined){
                  if(user.data.validate !== "1"){
                    setLoggedIn(true);
                    setValidate(true);
                    setOnLoad(false);
                    return children;
                  }else{
                    setLoggedIn(true);
                    setOnLoad(false);
                    return <Navigate to="/profile" replace/>;
                  }
                }else if(user === false){
                  setOnLoad(false);
                  return <Navigate to="/login" replace />;
                }
              });
          return (
            <OnLoad />
          )
        }else{
          if (loggedIn) {
            if (validate) {
                return children;
            }else{
                return <Navigate to="/profile" replace/>;
            }
          }else{
              return <Navigate to="/login" replace />;
          }
        }
    }
};
export default ProtectedNotVer;