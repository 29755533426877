import React, { Component } from "react";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import condoService from "../../../services/condo.service";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


export default class Lettura extends Component {
  constructor(props) {
    super(props);
    this.state = {
        subCondoId: props.subCondoId,
        idLett: "",
        data_inizio: "",
        data_fine: "",
        consumo: "",
        tipologia: "",
        message: null,
        error: false,
        };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAnnulla = this.handleAnnulla.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDI = this.handleChangeDI.bind(this);
    this.handleChangeDF = this.handleChangeDF.bind(this);
    this.handleChangeCS = this.handleChangeCS.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      tipologia: event.target.value
    });
  };
  handleChangeDI = (event) => {
    this.setState({
      data_inizio: event.target.value
    });
  };
  handleChangeDF = (event) => {
    this.setState({
      data_fine: event.target.value
    });
  };
  handleChangeCS = (event) => {
    this.setState({
      consumo: event.target.value
    });
  };

  componentDidUpdate(propsPrec) {
    if (this.props.subCondoId !== propsPrec.subCondoId) {
      this.setState({
        idLett: "",
        consumo: "",
        data_inizio: "",
        data_fine: "",
        tipologia: "",
        subCondoId: this.props.subCondoId
      });
    }
    if (this.props.letturaRow !== propsPrec.letturaRow) {
      this.setState({
        idLett: this.props.letturaRow.id,
        consumo: this.props.letturaRow.consumo,
        data_inizio: this.props.letturaRow.data_inizio,
        data_fine: this.props.letturaRow.data_fine,
        tipologia: this.props.letturaRow.tipologia
      });
    }
  }

  handleAnnulla(event) { 
    event.preventDefault();
    this.setState({
      idLett: "",
      consumo: "",
      data_inizio: "",
      data_fine: "",
      tipologia: "",
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let condo = this.state.subCondoId;
    if (condo == null) {
      return false
    } else {
      if(this.state.idLett){
        condoService.editSubLettura(condo,this.state.idLett, this.state.data_inizio, this.state.data_fine, this.state.consumo, this.state.tipologia).then(
          (response) => {
            if (response.error) {
              const resMessage = (response.messages) ? response.messages : "Errore generico";
              this.setState({
                error: true,
                message: resMessage
              });
            } else {
              document.getElementById("create-sub-course-form").reset();
              this.setState({
                idLett: "",
                data_inizio: "",
                data_fine: "",
                consumo: "",
                tipologia: "",
                message: null,
                error: false
              });
              this.props.handleRefLett(condo)
              this.props.handler()
            }
          }
        );
      }else{
        condoService.insertSubLettura(condo, this.state.data_inizio, this.state.data_fine, this.state.consumo, this.state.tipologia).then(
          (response) => {
            if (response.error) {
              const resMessage = (response.messages) ? response.messages : "Errore generico";
              this.setState({
                error: true,
                message: resMessage
              });
            } else {
              document.getElementById("create-sub-course-form").reset();
              this.setState({
                data_inizio: "",
                data_fine: "",
                consumo: "",
                tipologia: "",
                message: null,
                error: false
              });
              this.props.handleRefLett(condo)
              this.props.handler()
            }
          }
        );
      }
    }

  };


  componentDidMount() {

  }

  render() {
    return (
      <Box id="create-sub-course-form" component="form" onSubmit={this.handleSubmit} noValidate
        sx={{
          boxShadow: 1,
          border: 0,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          p: 1,
          mb:3
        }}
        style={{ backgroundColor: "white" }}
      >
        {(this.state.error) ?
          <Alert severity="error">{this.state.message}</Alert>
          : ""
        }
        {(this.state.idLett) ?
          <Grid container spacing={1} sx={{mt:0, mb:1}}>
            <Grid item xs={12} sm={6} md={1}>
              <FormControl fullWidth>
                <TextField
                  id="idLett"
                  label="Id Lett."
                  value={this.state.idLett}
                  onChange={this.handleChangeDI}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: '100%' }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Button
                type="button"
                size="large"
                variant="contained"
                color="inherit"
                onClick={this.handleAnnulla}
                style = {{width: '100%',height: "100%"}}
              >
                Annulla
              </Button>
            </Grid>
          </Grid>
          :
          ""
        }
        <Grid container spacing={1} sx={{mt:0, mb:1}}>
          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth>
            <InputLabel id="tipologia_label">Tipologia</InputLabel>
              <Select
                labelId="tipologia_label"
                id="tipologia"
                value={this.state.tipologia}
                label="tipologia"
                style={{ width: '100%' }}
                onChange={this.handleChange}
              >
                <MenuItem key="EFFETTIVA" value="EFFETTIVA">EFFETTIVA</MenuItem>
                <MenuItem key="STIMATA" value="STIMATA">STIMATA</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              id="data_inizio"
              label="Inizio"
              type="date"
              value={this.state.data_inizio}
              onChange={this.handleChangeDI}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              id="data_fine"
              label="Fine"
              type="date"
              value={this.state.data_fine}
              onChange={this.handleChangeDF}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <TextField
              id="consumo"
              value={this.state.consumo}
              onChange={this.handleChangeCS}
              label="Consumo"
              name="consumo"
              style={{ width: '100%' }}
              error={this.state.error}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <></>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              style = {{width: '100%',height: "100%"}}
            >
              Salva
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
