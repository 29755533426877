import axios from "axios";
import { API_URL, authHeader } from "./auth-header";

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "user/login", {
        email,
        password
      })
      .then(response => {
        if (!response.data.error) {
          let user = {accessToken : response.data.data.token};
          localStorage.setItem("user", JSON.stringify(user));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "user/register", {
      username,
      email,
      password
    });
  }

  async getCurrentUser() {
    return axios
      .get(API_URL + "user/profile", { headers: authHeader() })
      .then(response => {
        if (!response.data.error) {
          return response.data;
        }

        return false;
      });
  }

  async checkMailToken(token_verify) {
    return axios
      .post(API_URL + "user/mail_verify", { token_verify })
      .then(response => {
        if (!response.data.error) {
          return response.data;
        }

        return false;
      });
  }

  async checkValidateToken(token_verify) {
    return axios
      .post(API_URL + "user/validate_user", { token_verify })
      .then(response => {
        if (!response.data.error) {
          return response.data;
        }

        return false;
      });
  }

  async checkPassToken(token_verify, password, chek_password) {
    return axios
      .post(API_URL + "user/pass_change", { token_verify, password, chek_password })
      .then(response => {
        return response.data;
      });
  }

  async checkPassReq(email) {
    return axios
      .post(API_URL + "user/pass_change_req", { email })
      .then(response => {
        return response.data;
      });
  }
  
}
export default new AuthService();