// export const API_URL = "http://localhost:8080/api/";
// export const API_URL = "https://api.multidev.chogolisa.it/api/";
export const API_URL = "https://api.609.chogolisa.it/api/";

// export const FILE_URL = "http://localhost:8080/";
export const FILE_URL = "https://api.609.chogolisa.it/";

export function authHeader() {
  let user = localStorage.getItem('user');
 user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    return {
      Authorization: user.accessToken
    };
  } else {
    return {};
  }
}