import React, { Component } from "react";
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import CalculateIcon from '@mui/icons-material/Calculate';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import CloseIcon from '@mui/icons-material/Close';

import CedolinoDialogs from "./cedolinodial.component";

import Lettura from "./letture/lettura.component";
import LetturaList from "./letture/letturalist.component";


import {
  useGridApiRef,
  DataGrid,
} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import condoService from "../../services/condo.service";
import LoopIcon from '@mui/icons-material/Loop';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Tooltip from '@mui/material/Tooltip';

function ShowSend(params) {
  const classes = {
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: 1,
      color: 'red',
    },
    textPrimary: 'black',
  };

  if (params.row.send === "sending") {
    return (
      <div className={classes.root}>
        <IconButton
          color="primary"
          size="small"
          aria-label="save"
        >
          <LoopIcon fontSize="medium" style={{animation: "spin 4s linear infinite"}}  />
          <style>{`
            @keyframes spin {
                 0% { transform: rotate(360deg); }
                 100% { transform: rotate(0deg); }
            }
        `}</style>
        </IconButton>
      </div>
    )
  } else if (params.row.send === "send") {
    return (

      <div className={classes.root}>

        <IconButton
          color="success"
          size="small"
          aria-label="save"
        >
          <MarkEmailReadIcon fontSize="medium" />
        </IconButton>
      </div>
    )
  } else if (params.row.send === "error") {
    return (
      <div className={classes.root}>

        <IconButton
          color="error"
          size="small"
          aria-label="save"
        >
          <ReportGmailerrorredIcon fontSize="medium" />
        </IconButton>
      </div>
    )
  } else {
    return (
      <div className={classes.root}>

        SMS
      </div>
    )
  }
}

function showServizi(params) {
  let $tmp_f = (params.row.flag_fog === "1") ? "SI" : "NO";
  let $tmp_d = (params.row.flag_dep === "1") ? "SI" : "NO";
  return (
    <Typography variant="caption" color="black" >
      <b>Fogna: </b>{$tmp_f} <br />
      <b>Depurazione: </b>{$tmp_d}
    </Typography>
  )
}

function showImp(params) {
  if(params.row.importo !== "0"){
    return( 
      <Grid container spacing={0}>
        <Grid item xs={12} key={1}>
          <Typography variant="body1" color="black" >
            <b>Totale: </b>{params.row.importo} €
          </Typography></Grid>
        <Grid item xs={12} key={2}>
          <Typography variant="body2" color="black" >
            <b>T.Fiss.: </b>{params.row.imp_fisso} €<br />
            <b>T.Var.: </b>{params.row.imp_var} €<br />
            {(params.row.imp_comp)?
            <>
            <b>Comp.: </b>{params.row.imp_comp} €<br />
            </>
            :
            ""
            }
          </Typography></Grid>
      </Grid>
      )
  }else{
    return(
    <Typography variant="body1" color="black" >
      N/D
    </Typography>
    )
  }
}

function showDate(params) {
  if(params.row.data_inizio){
    return( 
      <Typography variant="body1" color="black" >
        <b>Inizio: </b>{params.row.data_inizio} <br/>
        <b>FIne: </b>{params.row.data_fine} 
      </Typography>
    )
  }
}


function UseGridApiRefPers () {
  return useGridApiRef();
}

export default class SubListist extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows,
      dialogCedolinoOpen: false,
      cedolinoRow: null,
      letturaRow: null,
      letturaRows: null,
      subCondoId: null,
      letturaName: null,
      };
      // this.handleSubmit = this.handleSubmit.bind(this);        
      const RowMenuCell = {}
      RowMenuCell.propTypes = {
        api: PropTypes.object.isRequired,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      };
      this.RowMenuCell = this.RowMenuCell.bind(this);
      this.handleLetturaOpen = this.handleLetturaOpen.bind(this);
      this.handleClickOpenCedolino = this.handleClickOpenCedolino.bind(this);
      this.handleCloseCedolino = this.handleCloseCedolino.bind(this); 
      this.handleLetturaClose = this.handleLetturaClose.bind(this); 
      this.handleLetturaRow = this.handleLetturaRow.bind(this);
      this.handleRefLett = this.handleRefLett.bind(this);
    }

    handleClickOpenCedolino = (row) => {
      this.setState({
        dialogCedolinoOpen:true,
        cedolinoRow: row
      })
    };
    handleCloseCedolino = () => {
      this.setState({
        dialogCedolinoOpen:false,
        cedolinoRow: null
      })
    };
    handleRefLett = (id) => {
      condoService.getSubLetture(id).then(
        (responseLetture) => {
            this.setState({
              letturaRows:responseLetture,
            })
        });
    };
    handleLetturaOpen = (row) => {
      condoService.getSubLetture(row.id).then(
        (responseLetture) => {
            this.setState({
              letturaOpen:true,
              letturaRows:responseLetture,
              letturaName:row.denominazione,
              subCondoId: row.id
            })
        });
    };
    handleLetturaClose = () => {
      this.setState({
        letturaOpen:false,
        letturaRow: null,
        subCondoId: null
      })
    };
    handleLetturaRow = (row) => {
      this.setState({
        letturaRow: row
      })
    }
    
    RowMenuCell(props) {
      const { api, id } = props;
      const classes = {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          gap: 1,
          color: 'red',
        },
        textPrimary: 'black'
      };
      const isInEditMode = api.getRowMode(id) === 'edit';

      const handleCedolinoClick = (event) => {
        event.stopPropagation();
        const row = api.getRow(id);
        this.handleClickOpenCedolino(row);
      };

      const handleLetturaClick = (event) => {
        event.stopPropagation();
        const row = api.getRow(id);
        this.handleLetturaOpen(row);
      };

      const handleCalcClick = (event) => {
        event.stopPropagation();
        const row = api.getRow(id);
        condoService.calcSingSub(row).then(
          (response) => {
            if (response.error) {
            } else {
              api.updateRows([{ ...response}]);
            }
          }
        );
      };

      const handleEditClick = (event) => {
        event.stopPropagation();
        api.setRowMode(id, 'edit');
      };
    
      const handleSaveClick = (event) => {
        event.stopPropagation();
        api.commitRowChange(id);
        api.setRowMode(id, 'view');

        const row = api.getRow(id);
        condoService.editSub(row).then(
          (response) => {
            if (response.error) {
            } else {
              api.updateRows([{ ...row, isNew: false }]);
              this.props.handler()
            }
          }
        );
      };
    
      const handleDeleteClick = (event) => {
        event.stopPropagation();
        condoService.deleteSub(id).then(
          (response) => {
            if (response.error) {
            } else {
              api.updateRows([{ id, _action: 'delete' }]);
              this.props.handler()
            }
          }
        );
      };
    
      const handleCancelClick = (event) => {
        event.stopPropagation();
        api.setRowMode(id, 'view');
    
        const row = api.getRow(id);
        if (row.isNew) {
          api.updateRows([{ id, _action: 'delete' }]);
        }
      };
    
      if (isInEditMode) {
        return (
          <div className={classes.root}>
            <IconButton
              color="primary"
              size="small"
              aria-label="save"
              onClick={handleSaveClick}
            >
              <SaveIcon fontSize="medium" />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              aria-label="cancel"
              className={classes.textPrimary}
              onClick={handleCancelClick}
            >
              <CancelIcon fontSize="medium" />
            </IconButton>
          </div>
        );
      }
    
      return (
        <div className={classes.root}>
          <Tooltip title="Cedolino informativo">
            <IconButton
              color="inherit"
              size="small"
              aria-label="calc"
              onClick={handleCedolinoClick}
            >
              <ForwardToInboxIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Inserisci lettura">
            <IconButton
              color="inherit"
              size="small"
              aria-label="calc"
              onClick={handleLetturaClick}
            >
              <BloodtypeIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Esegui calcolo per singola abitazione">
            <IconButton
              color="inherit"
              size="small"
              aria-label="calc"
              onClick={handleCalcClick}
            >
              <CalculateIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifica">
            <IconButton
              color="inherit"
              size="small"
              aria-label="edit"
              onClick={handleEditClick}
            >
              <EditIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancella">
            <IconButton
              color="inherit"
              size="small"
              aria-label="delete"
              onClick={handleDeleteClick}
            >
              <DeleteIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    componentDidUpdate(propsPrec) {
      if (this.props.rows !== propsPrec.rows) {
          this.setState({
            rows: this.props.rows
          });
      }
      if (this.props.letturaRow !== propsPrec.letturaRow) {
          this.setState({
            letturaRow: this.props.letturaRow
          });
      }
    }

    render() {
      const apiRef = UseGridApiRefPers
      const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const columns = [
      // { field: 'fiscal_code', headerName: 'Codice fiscale', flex:1,  editable: true },
      { field: 'denominazione', headerName: 'Denominazione', minWidth: 150, flex:0.5, editable: true },
      // { field: 'telefono', headerName: 'Telefono',flex:0.5, editable: true },
      { field: 'utilizzo', headerName: 'Utilizzo', minWidth: 200, flex:0.4 },
      { field: 'flag_fog', headerName: 'Servizi', minWidth: 80, flex:0.3, renderCell:showServizi},
      { field: 'ncf', headerName: 'NCF', minWidth: 50, flex:0.05, editable: true },
      { field: 'data_inizio', headerName: 'Date', minWidth: 150, flex:0.4, renderCell:showDate},
      { field: 'gg', headerName: 'GG', minWidth: 50, flex:0.1 },
      { field: 'consumo', headerName: 'Co', minWidth: 50, flex:0.1 },
      { field: 'importo', headerName: 'Importo', minWidth: 150, flex:0.4,editable: false, renderCell:showImp},
      { field: 'send', headerName: 'Invio', minWidth: 60,  flex:0.1,editable: false, renderCell:ShowSend},
      {
        field: 'actions',
        headerName: 'Azioni',
        renderCell: this.RowMenuCell,
        sortable: false,
        minWidth: 200, 
        flex:0.5,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
      },
    ];

  return (
    <>
    <div style={{ height: 500, width: '100%' , backgroundColor: "white" }}>
      <DataGrid
        rows={this.state.rows}
        rowHeight={105}
        columns={columns}
        apiRef={apiRef}
        editMode="row"
        sx={{
          boxShadow: 1,
          border: 0,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
        }}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        componentsProps={{
          toolbar: { apiRef },
        }}
      />
      <CedolinoDialogs open={this.state.dialogCedolinoOpen} onClose={this.handleCloseCedolino} condoItem={this.state.cedolinoRow}/>
    </div>
    {(this.state.letturaOpen)?
    <>
    <div>
        <Typography component="h2" variant="h5" sx={{ mt: 3 }}>
            Inserisci Lettura {this.state.letturaName}
            <IconButton aria-label="delete" onClick={this.handleLetturaClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </Typography>
        <Lettura open={this.state.letturaOpen} onClose={this.handleLetturaClose} subCondoId={this.state.subCondoId} letturaRow={this.state.letturaRow} handler={this.props.handler} handleRefLett={this.handleRefLett}/>
        <LetturaList open={this.state.letturaOpen} rows={this.state.letturaRows} handleLetturaRow={this.handleLetturaRow} handler={this.props.handler}/>
    </div>
    </>
    :
    <></>
    }
    </>

  );
}
}