import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

function useForceUpdate(){
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

export default function DatiUtente(props) {
    const [user] = React.useState(props.user);
    const [message, setMessage] = React.useState(props.message);
    const [userfile, setUserfile] = React.useState(props.userfile);

    function handleChange(event) {
        let tmpUser = user;
        let tmpMessage = message;
        tmpUser[event.target.name] = event.target.value;
        props.updateUser(tmpUser);
        if(event.target.value ==="" && event.target.required){
            tmpMessage[event.target.name] = "Campo obbligatorio";
            props.updateMessage(tmpMessage);
        }else{
            delete tmpMessage[event.target.name];
            props.updateMessage(tmpMessage);
        }
        return setMessage(tmpMessage);
    }

    function handleFile(event) {
        let tmpUser = user;
        let tmpMessage = message;
        tmpUser[event.target.name] = event.target.value;
        props.updateUser(tmpUser);
        if(event.target.files && event.target.files[0]){
            delete tmpMessage[event.target.name];
            props.updateMessage(tmpMessage);
            setUserfile(event.target.files[0])
            props.updateUserfile(event.target.files[0])
        }else{
            tmpMessage[event.target.name] = "Campo obbligatorio";
            props.updateMessage(tmpMessage);
            setUserfile(0)
            props.updateUserfile(0)
        }
        return setMessage(tmpMessage);
    }

    const forceUpdate = useForceUpdate();

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Dati personali utente
            </Typography>
            <Grid container spacing={3} onChange={forceUpdate}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="nome"
                        name="nome"
                        label="Nome"
                        fullWidth
                        autoComplete="given-name"
                        variant="standard"
                        defaultValue={user.nome}
                        onChange={handleChange}
                        helperText={(message.nome) ? message.nome : ""}
                        error={(message.nome) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="cognome"
                        name="cognome"
                        label="Cognome"
                        fullWidth
                        autoComplete="family-name"
                        variant="standard"
                        defaultValue={user.cognome}
                        onChange={handleChange}
                        helperText={(message.cognome) ? message.cognome : ""}
                        error={(message.cognome) ? true : false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="E-Mail"
                        fullWidth
                        autoComplete="email"
                        variant="standard"
                        defaultValue={user.email}
                        onChange={handleChange}
                        helperText={(message.email) ? message.email : ""}
                        error={(message.email) ? true : false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="password"
                        name="password"
                        label="Password"
                        fullWidth
                        variant="standard"
                        defaultValue={user.password}
                        onChange={handleChange}
                        type="password"
                        helperText={(message.password) ? message.password : ""}
                        error={(message.password) ? true : false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="cf"
                        name="cf"
                        label="Codice fiscale"
                        fullWidth
                        autoComplete="tax-code"
                        variant="standard"
                        defaultValue={user.cf}
                        onChange={handleChange}
                        helperText={(message.cf) ? message.cf : ""}
                        error={(message.cf) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2, mb:-2}}>
                    <Typography variant="h7" >
                        Domicilio
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="indirizzo"
                        name="indirizzo"
                        label="Indirizzo"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="standard"
                        defaultValue={user.indirizzo}
                        onChange={handleChange}
                        helperText={(message.indirizzo) ? message.indirizzo : ""}
                        error={(message.indirizzo) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="comune"
                        name="comune"
                        label="Comune"
                        fullWidth
                        autoComplete="shipping address-level2"
                        variant="standard"
                        defaultValue={user.comune}
                        onChange={handleChange}
                        helperText={(message.comune) ? message.comune : ""}
                        error={(message.comune) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="pr"
                        name="pr"
                        label="Provincia"
                        fullWidth
                        variant="standard"
                        defaultValue={user.pr}
                        onChange={handleChange}
                        helperText={(message.pr) ? message.pr : ""}
                        error={(message.pr) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="cap"
                        name="cap"
                        label="CAP"
                        fullWidth
                        autoComplete="shipping postal-code"
                        variant="standard"
                        defaultValue={user.cap}
                        onChange={handleChange}
                        helperText={(message.cap) ? message.cap : ""}
                        error={(message.cap) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2}}>
                    <Button variant={(userfile) ? "outlined" : "contained"} sx={{ mr: 2}} component="label" onChange={handleFile} startIcon={(userfile)?<ChangeCircleIcon />:""} color={(message.userfile) ? "error" : "primary"}>
                        {(userfile)? "Cambia file" : "Documento di riconoscimento" }
                        <input type="file" name="userfile" hidden  onChange={handleFile}/>
                    </Button>
                    {" "}
                    {userfile.name}
                    <br />
                    {(message.userfile) ?
                    <Typography variant="caption" gutterBottom color="error">
                        {message.userfile}
                    </Typography> :
                    ""
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}