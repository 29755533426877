import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 3}}>
    {'Copyright © '}
        <Link color="inherit" href="https://chogolisa.it/" target="_blank">
        Chogolisa
        </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
  );
}
