import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AuthService from "../services/auth.service";

export class PassChangeReq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      error: false,
      check: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    AuthService.checkPassReq(data.get('email')).then(
      (response) => {
        console.log(response)
        if (response.error === true) {
          const resMessage = (response.messages) ? response.messages : (response.message.email) ? response.message.email : (response.message.password) ? response.message.password : "Errore generico";
          this.setState({
            error: true,
            message: resMessage,
          });
        } else {
          this.setState({
            error: false,
            check: true,
          });      
        }
      }
    );
  };



  render() {
      return (
        (this.state.check === false) ?
        <>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 1,
                border: 0,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
              }}
              style={{ backgroundColor: "white" }}
            >
              <Grid container style={{ background: '#f8f8f8', color: "#393244", }} sx={{ p: 2 }}>
                <Grid item xs={12} alignItems="center" textAlign={"left"}>
                  <div>
                    <Typography component="h1" variant="h5">
                      Cambia password
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ p: 2 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Indirizzo E-Mail"
                  name="email"
                  autoComplete="email"
                  error={this.state.error}
                  autoFocus
                />
                {(this.state.error) ?
                  <Alert severity="error">{this.state.message}</Alert>
                  : ""
                }

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Invia Mail per cambio password
                </Button>
                <Grid container>
                  <Grid item>
                    <Link href="/login" variant="body2">
                      {"Indietro"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </>
          :
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ textAlign: 'center' }} variant="h4" component="div">
                MAIL INVIATA CON SUCCESSO
              </Typography>
              <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
                A breve riceverá alla mail indicata un link dal quale proseguire con il ripristino di una nuova password per il suo account.
              </Typography>
              <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
                Per qualsiasi comunicazione e/o segnalazione può contattarci all’indirizzo mail <b>609@chogolisa.it</b>
              </Typography>
              <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
                Cordialmente.
              </Typography>
              <Grid container justify="center">
              </Grid>
            </CardContent>
          </Card>
            
      );  
  }

}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <PassChangeReq {...props} navigate={navigate} />
}

export default WithNavigate