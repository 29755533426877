import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AuthService from "../services/auth.service";
import OnLoad from './onload';

function checkToken(token_verify, setCheck) {
  AuthService.checkValidateToken(token_verify).then(
    (user) => {
      if (user.data !== undefined) {
        setCheck("VALID");
      } else {
        setCheck("NOT_VALID");
      }
    });
}

export default function ValidateUser() {

  const token_verify = useParams();
  const [check, setCheck] = React.useState(false);
  useEffect(() => {
    checkToken(token_verify, setCheck);
  }, [token_verify])

  return (<>
    {(check === "VALID") ?
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ textAlign: 'center' }} variant="h4" component="div">
            UTENTE VALIDATO CON SUCCESSO
          </Typography>
          <Grid container justify="center">
          </Grid>
        </CardContent>
      </Card>
      :
      (check === "NOT_VALID") ?
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ textAlign: 'center' }} variant="h4" component="div">
            ERRORE CONTATTA ASSISTENZA
          </Typography>
          <Grid container justify="center">
          </Grid>
        </CardContent>
      </Card>
      :
      <OnLoad />
    }
  </>
  );
}
