import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";

export class InfoMassivoDialogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
        };
    }
    componentDidUpdate(propsPrec) {
        if (this.props.open !== propsPrec.open) {
            this.setState({
                open: this.props.open,
            });
        }
    }
    render() {
        return (
            <Dialog
                onClose={this.props.onClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.open}
                fullWidth={true}
                maxWidth={'lg'}
                >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <IconButton
                    aria-label="close"
                    onClick={this.props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                Gestione dell'importazione massiva 
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={0} key={0} sx={{mt:2, pr:3}}>
                        <Grid item xs={12} key={12}>
                        <Typography variant="body1" gutterBottom>
                        Gentile Amministratore,
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        da questa sezione potrà importare massivamente tutti i condomini da Lei gestiti.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        Nel caso volesse delgare a noi della Chogolisa tale operazione, le basterà inviare l'estrazione dei condomini e condomini gestiti ed inviarla in formato excel all'indirizzo <b>609@chogolisa.it</b>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        Cordialmente. 
                        </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.props.onClose}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
            );
    }
}
export default InfoMassivoDialogs
