import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { FILE_URL } from '../../services/auth-header';

function useForceUpdate(){
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

export default function DatiSocieta(props) {
    const [user] = React.useState(props.user);
    const [platformList] = React.useState(props.platformList);
    const [message, setMessage] = React.useState(props.message);

    function handlerAutoPlatform(value){
        let tmpUser = user;
        let tmpMessage = message;
        tmpUser["platform"] = value['platform_id'];
        props.updateUser(tmpUser);
        if(value ===""){
            tmpMessage["platform"] = "Campo obbligatorio";
            props.updateMessage(tmpMessage);
        }else{
            delete tmpMessage["platform"];
            props.updateMessage(tmpMessage);
        }
        return setMessage(tmpMessage);
    }
  

    function handleChange(event) {
        let tmpUser = user;
        let tmpMessage = message;
        tmpUser[event.target.name] = event.target.value;
        props.updateUser(tmpUser);
        if(event.target.value ==="" && event.target.required){
            tmpMessage[event.target.name] = "Campo obbligatorio";
            props.updateMessage(tmpMessage);
        }else{
            delete tmpMessage[event.target.name];
            props.updateMessage(tmpMessage);
        }
        return setMessage(tmpMessage);
    }

    const forceUpdate = useForceUpdate();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Dati Amministratore
            </Typography>
            <Grid container spacing={3} onChange={forceUpdate}>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="denominazione"
                        name="denominazione"
                        label="Denominazione"
                        fullWidth
                        autoComplete="company-name"
                        variant="standard"
                        defaultValue={user.denominazione}
                        onChange={handleChange}
                        helperText={(message.denominazione) ? message.denominazione : "Ragione Sociale o nome e cognome."}
                        error={(message.denominazione) ? true : false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="fiscal_code"
                        name="fiscal_code"
                        label="C.F./P.iva"
                        fullWidth
                        autoComplete="tax-code"
                        variant="standard"
                        defaultValue={user.fiscal_code}
                        onChange={handleChange}
                        helperText={(message.fiscal_code) ? message.fiscal_code : ""}
                        error={(message.fiscal_code) ? true : false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="pec"
                        name="pec"
                        label="PEC"
                        fullWidth
                        autoComplete="pec"
                        variant="standard"
                        defaultValue={user.pec}
                        onChange={handleChange}
                        helperText={(message.pec) ? message.pec : ""}
                        error={(message.pec) ? true : false}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="email_amministratore"
                        name="email_amministratore"
                        label="E-Mail"
                        fullWidth
                        autoComplete="email"
                        variant="standard"
                        defaultValue={user.email_amministratore}
                        onChange={handleChange}
                        helperText={(message.email_amministratore) ? message.email_amministratore : ""}
                        error={(message.email_amministratore) ? true : false}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    required
                    id="platform"
                    name="platform"
                    autoHighlight
                    getOptionLabel={(option) => option.descrizione}
                    options={(platformList)? platformList : []}
                    onChange={(event, value) => handlerAutoPlatform(value)} 
                    fullWidth
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <Grid item xs={2}>
                                <img
                                loading="lazy"
                                width="100%"
                                src={FILE_URL + `storable/${option.logo_url.toLowerCase()}`}
                                srcSet={FILE_URL + `storable/${option.logo_url.toLowerCase()} 2x`}
                                alt=""
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <></>
                            </Grid>
                            <Grid item xs={9}>
                            {option.descrizione}
                            </Grid>
                        </Box>
                      )}
                    renderInput={(params) => <TextField {...params} label="Azienda di riferimento" variant="standard" />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2, mb:-2}}>
                    <Typography variant="h7" >
                        Sede legale
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="indirizzo_amministratore"
                        name="indirizzo_amministratore"
                        label="Indirizzo"
                        fullWidth
                        autoComplete="shipping address-line1"
                        variant="standard"
                        defaultValue={user.indirizzo_amministratore}
                        onChange={handleChange}
                        helperText={(message.indirizzo_amministratore) ? message.indirizzo_amministratore : ""}
                        error={(message.indirizzo_amministratore) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="comune_amministratore"
                        name="comune_amministratore"
                        label="Comune"
                        fullWidth
                        autoComplete="shipping address-level2"
                        variant="standard"
                        defaultValue={user.comune_amministratore}
                        onChange={handleChange}
                        helperText={(message.comune_amministratore) ? message.comune_amministratore : ""}
                        error={(message.comune_amministratore) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="pr_amministratore"
                        name="pr_amministratore"
                        label="Provincia"
                        fullWidth
                        variant="standard"
                        defaultValue={user.pr_amministratore}
                        onChange={handleChange}
                        helperText={(message.pr_amministratore) ? message.pr_amministratore : ""}
                        error={(message.pr_amministratore) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="cap_amministratore"
                        name="cap_amministratore"
                        label="CAP"
                        fullWidth
                        autoComplete="shipping postal-code"
                        variant="standard"
                        defaultValue={user.cap_amministratore}
                        onChange={handleChange}
                        helperText={(message.cap_amministratore) ? message.cap_amministratore : ""}
                        error={(message.cap_amministratore) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="phone_no"
                        name="phone_no"
                        label="Telefono"
                        fullWidth
                        autoComplete="shipping phone"
                        variant="standard"
                        defaultValue={user.phone_no}
                        onChange={handleChange}
                        helperText={(message.phone_no) ? message.phone_no : ""}
                        error={(message.phone_no) ? true : false}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}