import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import AuthService from "../services/auth.service";
import condoService from "../services/condo.service";

import Condo from "../components/condominio/condo.component";
import tariffaService from "../services/tariffa.service";

export class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: false,
            currentCondo: false,
            condoList: [],
            condoSubList: [],
            utilizzoSet: [],
            comuniList:[],
            showLM: false,
            showNM: false,
            showCT: false,
        };
    }

    componentDidMount() {
        let user = localStorage.getItem('user');
        if (user == null) { } else {
            AuthService.getCurrentUser().then(
                (response) => {
                    if (response) {
                        condoService.get().then(
                            (responseCond) => {
                                tariffaService.getUse().then(
                                    (responseTariffa) => {
                                        tariffaService.getComuniList().then(
                                            (responseComuni) => {
                                                this.setState({
                                                    currentUser: response.data,
                                                    condoList: responseCond,
                                                    utilizzoSet: responseTariffa,
                                                    comuniList: responseComuni
                                                });
                                            }
                                        )
                                    }
                                )
                            });
                    } else { }
                }
            );
        }
    }

    render() {
        return (
                <Container component="main" maxWidth="xxl" >
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                    </Box>
                    <Box
                        sx={{
                            marginTop: 1,
                            paddingBottom: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',
                        }}
                        >
                    
                    <Condo condoList={this.state.condoList} utilizzoSet={this.state.utilizzoSet} comuniList={this.state.comuniList} pageType="gest"/>
                    </Box> 
                </Container>
        );
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Profile {...props} navigate={navigate} />
}

export default WithNavigate