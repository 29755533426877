import axios from 'axios';
import { API_URL, authHeader } from "./auth-header";

class UserService {

  getUserBoard() {
    return axios.get(API_URL + 'user/profile', { headers: authHeader() });
  }

  async registration(userData, userFile) {
    const formData = new FormData();
    Object.keys(userData).map(function(key, index) {
      formData.append(
        key,
        userData[key]
      );
      return true;
    });
    formData.append(
      "userfile",
      userFile
    );
    const response = await axios
      .post(API_URL + "user/register", formData);
    if (!response.data.error) {
      let user = { accessToken: response.data.data.token };
      localStorage.setItem("user", JSON.stringify(user));
      return response.data.data;
    }
    return response.data;
  }

  getPlatformList() {
    return axios.get(API_URL + 'platform/get_list', { headers: authHeader() }).then(response => {
      if (!response.data.error) {
        return response.data.data;
      }

      return false;
    });
  }
}

export default new UserService();