import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";

export class InfoCalcDialogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
        };
    }
    componentDidUpdate(propsPrec) {
        if (this.props.open !== propsPrec.open) {
            this.setState({
                open: this.props.open,
            });
        }
    }
    render() {
        return (
            <Dialog
                onClose={this.props.onClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.open}
                fullWidth={true}
                maxWidth={'lg'}
                >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <IconButton
                    aria-label="close"
                    onClick={this.props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <CloseIcon />
                    </IconButton>
                Informazioni sul Calcolo Tariffario 
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={0} key={0} sx={{mt:2, pr:3}}>
                        <Grid item xs={12} key={12}>
                        <Typography variant="body1" gutterBottom>
                        L’Adempimento alla delibera ARERA 609/2021/R/Idr prevede “di mettere a disposizione dell’utenza condominiale o suo delegato uno <b>strumento di calcolo</b> di semplice utilizzo che consenta la ripartizione degli importi fatturati tra ciascuna utenza indiretta secondo i criteri stabiliti nel TICSI. Lo strumento di calcolo dovrà prevedere l’inserimento delle informazioni pertinenti, con particolare riferimento a quelle relative a: numero di unità immobiliari suddivise per tipologia di utenza, consumo (rilevato o stimato) di ciascuna unità immobiliare e, per le sole utenze domestiche, numerosità degli abitanti a cui si applica la tariffa pro capite”.. 
                         <a href="https://www.arera.it/it/docs/21/609-21.htm"  target="_blank" rel="noreferrer" >link</a>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        <b>B4</b> è lo strumento di calcolo messo a disposizione dal Servizio Idrico Integrato agli amministratori condominiali.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        Grazie a tale strumento ogni amministratore è in grado di censire i propri condomini e ripartire per condomino il totale trascritto sulla fattura idrica ricevuta nel pieno rispetto della previsione normativa 609/2021/R/Idr.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        Chogolisa Srl è la società incaricata a supportare le esigenze degli amministratori durante l’utilizzo informatico e amministrativo della piattaforma <b>B4</b>. 
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        Basterà scrivere a <b>609@chogolisa.it</b> per ricevere supporto per ogni tipologia di problematica riscontrata:
                        <ul>
                            <li>Importazione massiva</li>
                            <li>Variazione di NCF</li>
                            <li>Trascrizione e modifiche di consumi</li>
                            <li>Difformità tra fattura idrica ricevuta e ripartizione su ogni condominio</li>
                            <li>Compensazione e acconto per mancate e ritardate letture;</li>
                            <li>Varie</li>
                        </ul>
                        I manuali di utilizzo in pdf e i video dimostrativi saranno sempre consultabili nella sezione medi della piattaforma B4
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                        Claudio Valeri
                        </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.props.onClose}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
            );
    }
}
export default InfoCalcDialogs
