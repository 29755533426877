import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AuthService from "../services/auth.service";
import OnLoad from './onload';

function getType(setType) {
  AuthService.getCurrentUser().then(
    (user) => {
      if (user.data !== undefined) {
        switch (user.data.validate) {
          case "2":
            setType("VALID");
            break;
          default:
            setType("NOT_VALID");
            break;
        }
      } else {
        setType(false);
      }
    });
}

export default function OnVerify() {
  const [type, setType] = React.useState(false);
  useEffect(() => {
    getType(setType);
  }, [])

  return (
    <Card sx={{ minWidth: 275 }}>
      {(type === "NOT_VALID") ?
        <>
          <CardContent>
            <Typography sx={{ textAlign: 'center', mb: 1.5 }} variant="h4" component="div">
              REGISTRAZIONE QUASI COMPLETATA
            </Typography>
            <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
              La invitiamo ad accedere alla mail da Lei indicata in fase di registrazione al portale B4 e a validare tutta la procedura di inserimento dati appena eseguita cliccando sul link notificato. Se entro due ore dalla visualizzazione di questo messaggio non dovesse ricevere alcuna mail, La invitiamo a verificare prima nella sezione Spam della sua casella elettronica poi a contattarci all’indirizzo mail <b>609@chogolisa.it</b>.
            </Typography>
            <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
              Cordialmente.
            </Typography>
            <Grid container justify="center">
            </Grid>
          </CardContent>
        </>
        :
        (type === "VALID") ?
          <>
            <CardContent>
              <Typography sx={{ textAlign: 'center' }} variant="h4" component="div">
                MAIL VERIFICATA CON SUCCESSO
              </Typography>
              <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
                Al termine del controllo sulla coerenza dei dati da Lei indicati nel portale <b>B4</b>, procederemo ad inviare mail di conferma di avvenuta attivazione del Suo account. Nel caso dovessimo riscontrare disallineamenti, procederemo a contattarla prontamente.
              </Typography>
              <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
                Per qualsiasi comunicazione e/o segnalazione può contattarci all’indirizzo mail <b>609@chogolisa.it</b>
              </Typography>
              <Typography sx={{ fontSize: 18, mb: 1.5, textAlign: 'justify' }} color="text.primary">
                Cordialmente.
              </Typography>
              <Grid container justify="center">
              </Grid>
            </CardContent>
          </>
          :
          <OnLoad />
      }
    </Card>
  );
}
